<template>
  <b-overlay :show="show" variant="transparent" opacity="0.99" blur="5px" rounded="sm">
    <template #overlay>
      <div class="text-center">
        <b-icon-controller font-scale="3" animation="cylon" />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="bg">
      <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
          <!-- Login v1 -->
          <b-card class="mb-0">
            <b-form-group v-if="domain === 'http://localhost:8080'">
              <label for="web">ชื่อเว็บ</label>
              <v-select v-model="web" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text"
                :options="listWeb" :reduce="text => text" class="bg-white shadow-sm border-0" @input="selectWeb()" />
            </b-form-group>
            <validation-observer ref="loginForm" #default="{ invalid }">
              <b-form class="auth-login-form" @submit.prevent="login">
                <!-- email -->
                <b-form-group>
                  <label for="Username">ชื่อผู้ใช้งาน</label>
                  <validation-provider #default="{ errors }" name="Username" vid="Username" rules="required">
                    <b-form-input id="Username" v-model="userEmail" :state="errors.length > 0 ? false : null"
                      name="Username" />
                    <small class="text-danger">{{ errors[0] ? 'กรอกชื่อผู้ใช้ให้ถูกต้อง' : '' }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <label for="login-password">รหัสผ่าน</label>
                  <validation-provider #default="{ errors }" name="Password" vid="password" rules="required">
                    <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                      <b-form-input id="login-password" v-model="password" :state="errors.length > 0 ? false : null"
                        class="form-control-merge" :type="passwordFieldType" name="login-password" />
                      <b-input-group-append is-text>
                        <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility" />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] ? 'กรอกรหัสผ่านให้ถูกต้อง' : '' }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <b-form-group>
                  <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1">
                    <span class="text-white">จดจำฉันไว้ในระบบ</span>
                  </b-form-checkbox>
                </b-form-group>

                <!-- submit buttons -->
                <b-button variant="primary" type="submit" block :disabled="invalid">
                  เข้าสู่ระบบ
                </b-button>
              </b-form>
            </validation-observer>

            <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->
          </b-card>
          <!-- /Login v1 -->
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import vSelect from 'vue-select'
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { email, required } from '@validations'
import {
  BButton, BCard, BCardText,
  // BCardTitle,
  BForm, BFormCheckbox, BFormGroup,
  BFormInput, BInputGroup, BInputGroupAppend, BOverlay,
  VBTooltip,
} from 'bootstrap-vue'
import axios from 'axios'
import BUID from 'uniquebrowserid'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    vSelect,
    // BImg,
    // BLink,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // BCardTitle,
    BOverlay,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      ref: null,
      show: false,
      OTP: '',
      nextstep: 0,
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
      ag: 1,
      web: 'https://admin.ufa-bak-7777.com',
      listWeb: [
        'https://admin.ufa-bak-7777.com',
      ],
      domain: '',
      AgLog: {
        ip: null,
        latitude: '',
        longitude: '',
        buid: new BUID().completeID(),
      },
      interval: null,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  // destroyed() {
  //   clearInterval(this.interval)
  // },
  mounted() {
    this.domain = window.location.origin
    this.getagid(window.location.origin)
    this.GetLocation()
    this.GetIp()
  },
  methods: {
    GetLocation() {
      if ('geolocation' in navigator) {
        navigator.geolocation.getCurrentPosition(position => {
          const { latitude } = position.coords
          const { longitude } = position.coords
          this.AgLog.latitude = latitude
          this.AgLog.longitude = longitude
        })
      }
    },
    async GetIp() {
      await axios
        .get('https://api.ipify.org')
        .then(response => {
          this.AgLog.ip = response.data
        })
        .catch(error => console.log(error))
    },
    selectWeb() {
      localStorage.setItem('devsite', this.web)
      this.getagid(this.web)
    },
    // eslint-disable-next-line no-unused-vars
    getagid(domain) {
      let obj = {
        // site_admin: domain,
        site_admin: 'localhost:8080',
      }
      let endpoint;
      let origin = window.origin;

      if (origin.includes('ddufa')) {
        endpoint = 'https://api.ddufa-789bak.com/api';
        obj.site_admin = 'https://admin.ddufa-789bak.com'
      } else if (origin.includes('ufabrother')) {
        endpoint = 'https://api.ufabrother-bkend.com/api';
        obj.site_admin = 'https://admin.ufabrother-bkend.com'
      } else if (origin.includes('ufa9')) {
        endpoint = 'https://api.bo-ufa9.com/api';
        obj.site_admin = 'https://admin.bo-ufa9.com'
      } else {
        endpoint = 'http://localhost:4040/api';
      }


      this.$http
        .post(`${endpoint}/agent/showid`, obj)

        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.ag = response.data.id
          localStorage.setItem('endpoint', response.data.endpoint)
          this.show = false
        })
        .catch(error => console.log(error))
    },
    GetOTP() {
      this.show = true
      const obj = {
        name: this.userEmail,
      }
      this.$http
        .post('/otplogin/store', obj)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response.data.ref)
          this.ref = response.data.ref
          this.nextstep = 1
          this.show = false
        })
        .catch(error => console.log(error))
    },
    CheckOTP() {
      const obj = {
        name: this.userEmail,
        otps: this.OTP,
        agent_id: this.ag,
      }
      this.$http
        .post('/otplogin/check', obj)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.login()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'OTP ไม่ถูกต้อง',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: error,
            },
          })
        })
    },
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
              // agent_id: this.ag,
              agent_id: 1,
            })
            .then(response => {
              const userData = response.data
              console.log(userData)
              useJwt.setToken(response.data.token)
              useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              // console.log(userData.ability)
              this.$ability.update(userData.ability)
              if (userData.role === 'zean') {
                this.LogAdminZean()
              } else {
                this.LogAdmin()
              }

              this.$router
                .push(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Welcome to Image Dashboard',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'You have successfully logged ',
                    },
                  })
                })
                .catch(error => {
                  console.log(error)
                })
            })
            .catch(error => {
              // console.log(error.data.message)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ไม่สามารถเข้าระบบได้ในขณะนี้',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              })
            })
        }
      })
    },
    LogAdmin() {
      const params = {
        on_page: 'login',
        ip: this.AgLog.ip,
        latitude: this.AgLog.latitude,
        longitude: this.AgLog.longitude,
        buid: this.AgLog.buid,
      }
      this.$http.get('/admin/log', { params })
    },
    LogAdminZean() {
      const params = {
        on_page: 'login',
        ip: this.AgLog.ip,
        latitude: this.AgLog.latitude,
        longitude: this.AgLog.longitude,
      }
      this.$http.get('/admin/log/zean', { params })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
.bg {
  background-image: url('/bg2.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.card {
  border-radius: 14px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: rgba(56, 56, 56, 0.452);
}

label {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
